/* eslint-disable indent */
/**
 * Represents an Artikl.
 * @typedef {Object} Artikl
 * @property {string} id
 * @property {string} naziv
 * @property {number} kolicina
 * @property {string} mjernaJedinica
 * @property {string} mjernaJedinicaId
 * @property {number} jedinicnaCijenaBezPdv
 * @property {number} ukupnaCijenaBezPdv
 * @property {number} stopaPdv
 * @property {number} iznosPdv
 * @property {number} rabat
 * @property {number} netoUkupnaCijenaBezPdva
 * @property {number} ukupnaCijenaSPdv
 * @property {number} jedinicnaCijenaSPdv
 * @property {object} item
 */

export class Artikl {
  // eslint-disable-next-line space-before-function-paren
  constructor(artikl = {}) {
    this.setId(artikl?.id ?? '')
    this.setNaziv(artikl?.artikl ?? '')
    this.setKolicina(artikl?.kolicina ?? 0)
    this.setJedinicnaCijenaBezPdv(artikl?.jedinicnaCijenaBezPdv ?? 0)
    this.setUkupnaCijenaBezPdv(artikl?.ukupnaCijenaBezPdv ?? 0)
    this.setStopaPdv(artikl?.stopaPdv ?? 0)
    this.setIznosPdv(artikl?.iznosPdv ?? 0)
    this.setRabat(artikl?.rabat ?? 0)
    this.setNetoUkupnaCijenaBezPdva(artikl?.jedinicnaCijenaSRabatom ?? 0)
    this.setUkupnaCijenaSPdv(artikl?.ukupnaCijenaSPdv ?? 0)
    this.setJedinicnaCijenaSPdv(artikl?.jedinicnaCijenaSPdv ?? 0)
    this.setMjernaJedinicaID(artikl?.mjernaJedinica ?? '')
    this.setItem(artikl?.item ?? {})
  }

  setId (id) {
    this.id = id
  }

  setNaziv (naziv) {
    this.naziv = naziv
  }

  setKolicina (kolicina) {
    if (kolicina) { this.kolicina = kolicina * 1000 } else { this.kolicina = kolicina }
  }

  setJedinicnaCijenaBezPdv (jedinicnaCijenaBezPdv) {
    this.jedinicnaCijenaBezPdv = parseInt(((jedinicnaCijenaBezPdv * 100000).toFixed(2)))
  }

  setUkupnaCijenaBezPdv (ukupnaCijenaBezPdv) {
    this.ukupnaCijenaBezPdv = parseInt(((ukupnaCijenaBezPdv * 10000).toFixed(2)))
  }

  setStopaPdv (stopaPdv) {
    this.stopaPdv = stopaPdv * 100
  }

  setIznosPdv (iznosPdv) {
    this.iznosPdv = parseInt(((iznosPdv * 100).toFixed(2)))
  }

  setRabat (rabat) {
    this.rabat = rabat * 100
  }

  setNetoUkupnaCijenaBezPdva (netoUkupnaCijenaBezPdva) {
    this.netoUkupnaCijenaBezPdva = parseInt((netoUkupnaCijenaBezPdva * 100).toFixed(2))
  }

  setUkupnaCijenaSPdv (ukupnaCijenaSPdv) {
    this.ukupnaCijenaSPdv = parseInt((ukupnaCijenaSPdv * 100).toFixed(2))
  }

  setJedinicnaCijenaSPdv (jedinicnaCijenaSPdv) {
    this.jedinicnaCijenaSPdv = parseInt((jedinicnaCijenaSPdv * 100).toFixed(2))
  }

  setMjernaJedinicaID (mjernaJedinicaId) {
    this.mjernaJedinicaId = mjernaJedinicaId
  }

  setItem (item) {
    this.item = item
  }
}

/**
 * Represents a Primka.
 * @typedef {Object} Primka
 * @property {string} dobavljac
 * @property {string} brojPrimke
 * @property {Artikl[]} artikli
 * @property {string} nacinPlacanja
 * @property {number} ukupanIznosPdv
 * @property {number} ukupanIznosBezPdv
 * @property {number} ukupanIznosSPdv
 * @property {number} ukupnoPovratnaNaknada
 */

/** @type {Primka} */
export class Primka {
  // eslint-disable-next-line space-before-function-paren
  constructor() {
    this.idSkladista = ''
    this.nazivSkladista = ''
    this.idLokacije = ''
    this.prodajnoSkladiste = false
    this.idFirme = ''
    this.dobavljac = ''
    this.brojPrimke = ''
    this.artikli = []
    this.nacinPlacanja = ''
    this.ukupanIznosPdv = 0
    this.ukupanIznosBezPdv = 0
    this.ukupanIznosSPdv = 0
    this.ukupnoPovratnaNaknada = 0
    this.datum = ''
    this.ukupnoPovratnaNaknada = 0
    this.akcija = ''
    this.tipDokumenta = ''
    this.tipRabata = 0
    this.tipPoreza = 0
    this.povezanaPrimka = ''
  }

  setDatum (datum) {
    this.datum = parseInt((datum / 1000).toFixed(2))
  }

  setDobavljac (dobavljac) {
    this.dobavljac = dobavljac
  }

  setIdFirme (firma) {
    this.idFirme = firma
  }

  setIdSkladista (skladiste) {
    this.idSkladista = skladiste
  }

  setNazivSkladista (naziv) {
    this.nazivSkladista = naziv
  }

  setBrojPrimke (brojPrimke) {
    this.brojPrimke = brojPrimke
  }

  setNacinPlacanja (nacinPlacanja) {
    this.nacinPlacanja = nacinPlacanja
  }

  setUkupanIznosPdv (ukupanIznosPdv) {
    this.ukupanIznosPdv = parseInt(((ukupanIznosPdv * 100).toFixed(2)))
  }

  setUkupanIznosBezPdv (ukupanIznosBezPdv) {
    this.ukupanIznosBezPdv = parseInt(((ukupanIznosBezPdv * 100).toFixed(2)))
  }

  setUkupanIznosSPdv (ukupanIznosSPdv) {
    this.ukupanIznosSPdv = parseInt(((ukupanIznosSPdv * 100).toFixed(2)))
  }

  setUkupnoPovratnaNaknada (ukupnoPovratnaNaknada) {
    this.ukupnoPovratnaNaknada = this.getUkupnoPovratnaNaknada()
  }

  setIdLokacije (idLokacije) {
    this.idLokacije = idLokacije
  }

  setProdajnoSkladiste (prodajnoSkladiste) {
    this.prodajnoSkladiste = prodajnoSkladiste
  }

  setAkcija (akcija) {
    this.akcija = akcija
  }

  setTipDokumenta (tipDokumenta) {
    this.tipDokumenta = tipDokumenta
  }

  setTipRabata (tipRabata) {
    this.tipRabata = tipRabata
  }

  setTipPoreza (tipPoreza) {
    this.tipPoreza = tipPoreza
  }

  setBrojPovezanePrimke (brojPrimke) {
    this.povezanaPrimka = brojPrimke
  }

  setArtikl (artikl) {
    this.artikli.push(new Artikl(artikl))
  }

  getUkupnoPovratnaNaknada () {
    let povratnaNaknada = 0
    this.artikli.forEach(element => {
      if (element.id === '-1') { povratnaNaknada += element.ukupnaCijenaSPdv }
    })
    return povratnaNaknada
  }
}

export default Artikl
